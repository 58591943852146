/* App.css */

/* Import font Poppins từ Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Reset và thiết lập font mặc định */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
}

/* Thiết kế header */
.header-custom {
  background: linear-gradient(90deg, #ff6b6b, #ff4d4d);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.navbar-brand:hover {
  transform: scale(1.05);
}

.navbar-brand i {
  font-size: 1.5rem;
  color: #fff;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Navigation links */
.nav-link {
  font-size: 0.9rem !important;
  padding: 8px 12px !important;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #ffe4e1 !important;
}

/* Thanh tìm kiếm */
.search-form {
  max-width: 500px;
  width: 100%;
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  font-size: 1rem;
}

.search-input {
  padding-left: 40px !important;
  border-radius: 20px !important;
  border: none !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.search-input:focus {
  box-shadow: 0 0 0 3px rgba(255, 107, 107, 0.3) !important;
  outline: none !important;
}

/* Biểu tượng và nút */
.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff !important;
  margin-left: 15px;
  transition: all 0.3s ease;
}

.icon-link:hover {
  color: #ffe4e1 !important;
  transform: scale(1.1);
}

.icon-link i {
  font-size: 1.5rem;
}

.icon-label {
  font-size: 0.7rem;
  margin-top: 2px;
}

/* Dropdown */
.dropdown-toggle::after {
  margin-left: 5px;
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  font-size: 0.9rem;
  padding: 8px 16px;
}

.dropdown-item:hover {
  background-color: #ff6b6b;
  color: #fff;
}

/* Menu hamburger trên mobile */
.custom-toggler {
  border: none !important;
  color: #fff !important;
}

.custom-toggler i {
  font-size: 1.5rem;
}

/* Main content padding to avoid header overlap */
.main-content {
  padding-top: 70px;
}

/* Tối ưu trên mobile */
@media (max-width: 576px) {
  .header-custom {
    padding: 8px 0;
  }

  .container {
    width: 100%;
    padding: 0 15px;
  }

  .navbar-brand {
    margin: 0;
    flex-grow: 1;
    text-align: center;
  }

  .navbar-brand i {
    font-size: 1.2rem;
  }

  .logo-text {
    font-size: 1.2rem;
  }

  .search-form {
    max-width: 100%;
    margin-top: 8px;
  }

  .search-input {
    font-size: 0.85rem;
    padding: 6px 12px 6px 36px !important;
    border-radius: 30px !important;
    background: rgba(255, 255, 255, 0.9);
  }

  .search-icon {
    font-size: 0.9rem;
    left: 10px;
  }

  .icon-link {
    margin-left: 0;
    flex-direction: row;
    gap: 5px;
  }

  .icon-link i {
    font-size: 1.2rem;
  }

  .icon-label {
    display: inline !important;
    font-size: 0.9rem;
  }

  .navbar-collapse {
    background: linear-gradient(90deg, #ff6b6b, #ff4d4d);
    border-radius: 0 0 12px 12px;
    padding: 10px;
    margin-top: 5px;
  }

  .nav-link {
    font-size: 1rem !important;
    padding: 10px 15px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .main-content {
    padding-top: 80px;
  }

  .custom-toggler {
    order: -1;
  }

  .d-lg-none.icon-link {
    order: 1;
  }
}

/* Thiết kế danh mục sản phẩm */
.category-carousel {
  position: relative;
}

.category-scroll {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  scrollbar-width: none;
}

.category-scroll::-webkit-scrollbar {
  display: none;
}

.category-card {
  flex: 0 0 100px;
  height: 120px;
  background: linear-gradient(135deg, #ffffff, #f1f3f5);
  border-radius: 12px;
  padding: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-image-wrapper {
  width: 50px;
  height: 50px;
  margin: 0 auto 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ff6b6b, #ff8787);
  transition: all 0.3s ease;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.category-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: #2d3436;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.category-card.active {
  background: linear-gradient(135deg, #ff6b6b, #ff8787);
  box-shadow: 0 4px 12px rgba(255, 107, 107, 0.3);
}

.category-card.active .category-text {
  color: #fff;
}

.category-card.active .category-image-wrapper {
  background: #fff;
}

/* Nút điều hướng */
.category-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-nav-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

.category-nav-btn:disabled {
  background: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

.category-nav-left {
  left: -16px;
}

.category-nav-right {
  right: -16px;
}

/* Dots chỉ báo */
.category-dots {
  display: flex;
  gap: 6px;
}

.category-dot {
  width: 8px;
  height: 8px;
  background: #d1d5db;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.category-dot.active {
  background: #ff6b6b;
  width: 24px;
  border-radius: 12px;
}

/* Thiết kế thẻ sản phẩm */
.card {
  border: none !important;
  border-radius: 12px !important;
  background: linear-gradient(135deg, #ffffff, #f1f3f5) !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1) !important;
}

.card-img-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  object-fit: cover !important;
}

.card-body {
  padding: 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
}

.card-title {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #2d3436 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-bottom: 6px !important;
}

.card-text {
  font-size: 0.75rem !important;
  margin-bottom: 4px !important;
}

.price-section {
  margin-bottom: 8px !important;
}

.original-price {
  font-size: 0.75rem !important;
  color: #6c757d !important;
}

.sale-price {
  font-size: 0.85rem !important;
  font-weight: 600 !important;
}

.btn {
  font-size: 0.75rem !important;
  padding: 6px 10px !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
}

.btn-warning {
  background-color: #ffca2c !important;
  border-color: #ffca2c !important;
  color: #212529 !important;
}

.btn-warning:hover {
  background-color: #e0a800 !important;
  border-color: #e0a800 !important;
}

.btn-danger {
  background-color: #ff6b6b !important;
  border-color: #ff6b6b !important;
}

.btn-danger:hover {
  background-color: #ff4d4d !important;
  border-color: #ff4d4d !important;
}

.sale-badge .badge,
.featured-badge .badge {
  font-size: 0.7rem !important;
  padding: 4px 8px !important;
  border-radius: 10px !important;
}

/* Ảnh nền cho Sản phẩm bán chạy */
.best-sellers-section {
  background-image: url("https://placehold.co/1200x400/FFD700/FFFFFF?text=Best+Sellers+Background");
  background-size: cover;
  background-position: center;
  padding: 20px;
  border-radius: 12px;
  position: relative;
}

.best-sellers-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.best-sellers-section h2,
.best-sellers-section p,
.best-sellers-section .card {
  position: relative;
  z-index: 1;
}

/* Chỉnh sửa giao diện mobile */
@media (max-width: 576px) {
  /* Banner */
  .banner-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
  }
  
  .banner-container .category-carousel {
    max-height: 200px;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    .banner-container {
      flex-direction: column;
    }
  
    .banner-container .category-carousel {
      max-height: none;
      overflow-y: visible;
    }
  }
  .container .carousel-item img {
    height: 200px !important;
    object-fit: cover !important;
    border-radius: 12px !important;
  }

  .container .carousel-caption {
    background: rgba(0, 0, 0, 0.7) !important;
    padding: 8px 12px !important;
    border-radius: 8px !important;
    bottom: 15px !important;
  }

  .container .carousel-caption h3 {
    font-size: 1rem !important;
    margin-bottom: 4px !important;
  }

  .container .carousel-caption p {
    font-size: 0.75rem !important;
  }

  /* Tất cả sản phẩm */
  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 12px !important;
    max-height: 620px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    padding-bottom: 12px !important;
    scrollbar-width: thin !important;
    scrollbar-color: #ff6b6b #f1f1f1 !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .col {
    min-width: 160px !important;
    max-height: 310px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .card {
    height: 100% !important;
    width: 100% !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .card-img-top {
    height: 150px !important;
    object-fit: cover !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .card-title {
    font-size: 0.85rem !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .card-text {
    font-size: 0.7rem !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:not(:last-child) .btn {
    font-size: 0.7rem !important;
    padding: 4px 8px !important;
  }

  /* Sản phẩm bán chạy */
  .container.my-5 .d-flex.gap-3.overflow-auto {
    display: flex !important;
    flex-wrap: nowrap !important;
    gap: 12px !important;
    max-height: 300px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    padding-bottom: 12px !important;
    scrollbar-width: thin !important;
    scrollbar-color: #ff6b6b #f1f1f1 !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto > div {
    flex: 0 0 48% !important;
    min-width: 160px !important;
    max-width: 160px !important;
    max-height: 300px !important;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto .card {
    height: 100% !important;
    width: 100% !important;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto .card-img-top {
    height: 150px !important;
    object-fit: cover !important;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto .card-title {
    font-size: 0.85rem !important;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto .card-text {
    font-size: 0.7rem !important;
  }

  /* Sản phẩm nổi bật */
  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 12px !important;
    max-height: 620px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    padding-bottom: 12px !important;
    scrollbar-width: thin !important;
    scrollbar-color: #ff6b6b #f1f1f1 !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .col {
    min-width: 160px !important;
    max-height: 310px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .card {
    height: 100% !important;
    width: 100% !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .card-img-top {
    height: 150px !important;
    object-fit: cover !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .card-title {
    font-size: 0.85rem !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .card-text {
    font-size: 0.7rem !important;
  }

  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4:last-child .btn {
    font-size: 0.7rem !important;
    padding: 4px 8px !important;
  }

  /* Thanh cuộn */
  ::-webkit-scrollbar {
    height: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #ff6b6b !important;
    border-radius: 3px !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }
}

/* Responsive cho tablet */
@media (min-width: 576px) and (max-width: 768px) {
  .container.my-5 .row.row-cols-1.row-cols-md-2.row-cols-lg-4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .container.my-5 .d-flex.gap-3.overflow-auto > div {
    flex: 0 0 32%;
    min-width: 200px;
    max-width: 200px;
  }

  .admin-form-section .col-md-6 {
    margin-bottom: 15px;
  }
}

/* Style cho trang admin */
.admin-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.admin-section-title {
  color: #007bff;
  font-weight: 600;
  margin-bottom: 15px;
}

.admin-input {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.admin-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.admin-button {
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.admin-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Table */
.table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table thead.table-primary {
  background-color: #007bff;
  color: white;
}

.table tbody tr {
  transition: all 0.3s ease;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

/* Image Wrapper for Product Image */
.image-wrapper {
  position: relative;
  display: inline-block;
}

.product-image {
  transition: all 0.3s ease;
}

.product-image:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}

/* Thanh tìm kiếm trong admin */
.admin-search-form {
  max-width: 300px;
  margin-bottom: 20px;
}

.admin-search-input {
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.admin-search-input:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

/* Phân trang */
.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.pagination .page-link {
  border-radius: 5px;
  margin: 0 3px;
  transition: all 0.3s ease;
}

.pagination .page-link:hover {
  background-color: #e9ecef;
}

/* Form chỉnh sửa sản phẩm */
.admin-form-section {
  margin-bottom: 30px;
}

.admin-form-section .row {
  margin-bottom: 15px;
}

.admin-form-section label {
  font-weight: 500;
  color: #2d3436;
}

.admin-form-section .form-control,
.admin-form-section .form-select {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.admin-form-section .form-control:focus,
.admin-form-section .form-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

/* Biến thể sản phẩm */
.variant-list {
  margin-top: 15px;
}

.list-group-item {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

.variant-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.variant-item .row {
  align-items: center;
}

/* Trình soạn thảo văn bản (react-quill-new) */
.quill-editor .ql-container {
  min-height: 200px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.quill-editor .ql-container:focus-within {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.quill-editor .ql-toolbar {
  border-radius: 8px 8px 0 0;
  border: 1px solid #ced4da;
  border-bottom: none;
  background: #f8f9fa;
}

/* Responsive cho form chỉnh sửa sản phẩm */
@media (max-width: 576px) {
  .admin-form-section .row {
    margin-bottom: 10px;
  }

  .admin-form-section .col-md-6 {
    margin-bottom: 15px;
  }

  .admin-search-form {
    max-width: 100%;
  }
}
/* Style cho giỏ hàng */
.cart-title {
  color: #2d3436;
  font-size: 2rem;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-top: 2rem;
}

.cart-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: #ff6b6b;
  border-radius: 2px;
}

.cart-summary {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.cart-items {
  margin-bottom: 30px;
}

.cart-item {
  transition: all 0.3s ease;
}

.cart-card {
  border: none !important;
  border-radius: 12px !important;
  background: #fff !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.cart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1) !important;
}

.cart-card .card-body {
  padding: 20px !important;
}

.cart-card .card-title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.cart-card .text-muted {
  font-size: 0.9rem !important;
}

.cart-card .text-danger {
  font-size: 1.2rem !important;
}

.quantity-btn {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.quantity-btn:hover {
  background-color: #ff6b6b !important;
  color: #fff !important;
  border-color: #ff6b6b !important;
}

.quantity-display {
  font-size: 1rem;
  font-weight: 500;
  min-width: 30px;
  text-align: center;
}

.remove-btn {
  border-radius: 8px !important;
  transition: all 0.3s ease;
}

.remove-btn:hover {
  background-color: #d32f2f !important;
  transform: scale(1.05);
}

.cart-footer {
  position: sticky;
  bottom: 20px;
  z-index: 10;
}

.cart-footer .card {
  background: #fff !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.cart-footer h4 {
  font-size: 1.5rem;
  color: #2d3436;
}

.action-btn {
  padding: 12px 20px !important;
  border-radius: 8px !important;
  font-size: 1rem !important;
  transition: all 0.3s ease;
}

.action-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.empty-cart {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.empty-cart p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.empty-cart .btn {
  padding: 10px 20px !important;
  font-size: 1rem !important;
}

/* Responsive cho giỏ hàng */
@media (max-width: 576px) {
  .cart-title {
    font-size: 1.5rem;
    margin-top: 3rem;
  }

  .cart-summary {
    padding: 10px;
  }

  .cart-card .card-body {
    padding: 15px !important;
  }

  .cart-card .card-title {
    font-size: 1rem !important;
  }

  .cart-card .text-muted {
    font-size: 0.85rem !important;
  }

  .cart-card .text-danger {
    font-size: 1.1rem !important;
  }

  .cart-card img {
    width: 100px !important;
    height: 100px !important;
  }

  .quantity-btn {
    width: 35px;
    height: 35px;
  }

  .quantity-display {
    font-size: 0.9rem;
  }

  .remove-btn {
    font-size: 0.85rem !important;
    padding: 6px 12px !important;
  }

  .cart-footer {
    position: static;
    bottom: 0;
  }

  .cart-footer .card {
    padding: 15px !important;
  }

  .cart-footer h4 {
    font-size: 1.25rem;
  }

  .action-btn {
    font-size: 0.9rem !important;
    padding: 10px 15px !important;
  }
}
/* Cải tiến giao diện admin */
.admin-container {
  background: #f1f3f5;
}

.admin-content {
  background: #f8f9fa;
  min-height: 100vh;
}

.admin-sidebar {
  background: linear-gradient(180deg, #2d3436, #1e272e) !important;
  transition: all 0.3s ease;
}

.admin-sidebar-open {
  transform: translateX(0);
}

.admin-sidebar-closed {
  transform: translateX(-250px);
}

@media (min-width: 768px) {
  .admin-sidebar {
    transform: translateX(0) !important;
  }
}

@media (max-width: 767px) {
  .admin-sidebar {
    position: fixed;
    z-index: 1000;
  }
}

.admin-sidebar-title {
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  padding-bottom: 10px;
}

.admin-sidebar-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: #ff6b6b;
  border-radius: 2px;
}

.admin-nav-link {
  transition: all 0.3s ease;
  border-radius: 8px !important;
}

.admin-nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.admin-nav-link.active {
  background: #ff6b6b !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.admin-logout-btn {
  border-color: #ff4d4d !important;
  transition: all 0.3s ease;
}

.admin-logout-btn:hover {
  background: #ff4d4d !important;
  color: #fff !important;
  transform: scale(1.05);
}

.admin-card {
  border: none !important;
  border-radius: 12px !important;
  background: #fff !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
  transition: all 0.3s ease;
}

.admin-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1) !important;
}

.admin-card .card-header {
  background: linear-gradient(90deg, #007bff, #0056b3) !important;
  border-radius: 12px 12px 0 0 !important;
}

.admin-card .card-body {
  padding: 20px !important;
}

.admin-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.admin-table thead th {
  font-size: 0.9rem;
  font-weight: 600;
}

.admin-table tbody tr {
  transition: all 0.3s ease;
}

.admin-table tbody tr:hover {
  background: #e9ecef;
  transform: scale(1.01);
}

.admin-form .form-label {
  font-weight: 500;
  color: #2d3436;
}

.admin-form .input-group-text {
  background: #f1f3f5;
  border: 1px solid #ced4da;
  border-right: none;
}

.admin-form .form-control,
.admin-form .form-select {
  border-radius: 0 8px 8px 0 !important;
}

.admin-button {
  transition: all 0.3s ease;
}

.admin-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.admin-section-title {
  color: #007bff;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 1.25rem;
}

.admin-summary {
  background: #f8f9fa;
  border-left: 4px solid #007bff;
}

.admin-spinner {
  width: 3rem;
  height: 3rem;
}

/* Responsive cho admin */
@media (max-width: 576px) {
  .admin-sidebar {
    width: 200px;
  }

  .admin-sidebar-title {
    font-size: 1.25rem;
  }

  .admin-nav-link {
    font-size: 0.9rem;
  }

  .admin-card .card-body {
    padding: 15px !important;
  }

  .admin-table thead th {
    font-size: 0.85rem;
  }

  .admin-table tbody td {
    font-size: 0.85rem;
  }

  .admin-button {
    font-size: 0.9rem;
    padding: 8px 12px !important;
  }

  .admin-summary p {
    font-size: 0.9rem;
  }
}
/* Định dạng chung cho modal */
.auth-modal .modal-content {
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
}

/* Định dạng header của modal */
.auth-modal-header {
  background: linear-gradient(90deg, #ff6b6b, #ff4d4d);
  border-bottom: none;
  padding: 15px 20px;
}

.auth-modal-header .modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
}

.auth-modal-header .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

/* Định dạng body của modal */
.auth-modal-body {
  padding: 20px;
  font-size: 1rem;
  color: #495057;
  text-align: center;
}

/* Định dạng các nút trong modal */
.auth-modal-btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

/* Nút Đăng nhập */
.auth-modal-btn-login {
  background-color: #007bff;
  border-color: #007bff;
}

.auth-modal-btn-login:hover {
  background-color: #0056b3;
  border-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Nút Đăng ký */
.auth-modal-btn-register {
  background-color: #28a745;
  border-color: #28a745;
}

.auth-modal-btn-register:hover {
  background-color: #218838;
  border-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Nút Hủy */
.auth-modal-btn-cancel {
  color: #6c757d;
  border-color: #6c757d;
}

.auth-modal-btn-cancel:hover {
  color: #5a6268;
  border-color: #5a6268;
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

/* Định dạng footer của modal */
.auth-modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 10px 20px;
  justify-content: center;
}

/* Responsive cho modal trên mobile */
@media (max-width: 576px) {
  .auth-modal-header .modal-title {
    font-size: 1.1rem;
  }

  .auth-modal-body {
    padding: 15px;
    font-size: 0.9rem;
  }

  .auth-modal-btn {
    font-size: 0.9rem;
    padding: 6px 16px;
  }

  .auth-modal-footer {
    padding: 8px 15px;
  }
}
/* Tùy chỉnh ToastContainer để hiển thị ở dưới, chính giữa */
.toast-container-bottom-center {
  bottom: 20px !important;
  width: auto !important;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

/* Tùy chỉnh giao diện thông báo */
.Toastify__toast {
  border-radius: 8px !important;
  padding: 12px 20px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  min-width: 300px;
  max-width: 500px;
}

/* Tùy chỉnh màu sắc cho các loại thông báo */
.Toastify__toast--success {
  background: #28a745 !important;
}

.Toastify__toast--error {
  background: #dc3545 !important;
}
.Toastify__toast--success .Toastify__toast-body {
  color: #fff !important;
}

.Toastify__toast--info {
  background: #28a745 !important;
}

/* Tùy chỉnh nút đóng thông báo */
.Toastify__close-button {
  color: #fff !important;
  opacity: 0.8 !important;
}

.Toastify__close-button:hover {
  opacity: 1 !important;
}

/* Responsive cho thông báo trên mobile */
@media (max-width: 576px) {
  .Toastify__toast {
    min-width: 250px;
    max-width: 90%;
    font-size: 0.9rem !important;
    padding: 10px 15px !important;
  }

  .toast-container-bottom-center {
    bottom: 15px !important;
  }
}
/* Nút Zalo */
.zalo-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1000;
  animation: zaloExplosion 1.8s infinite ease-in-out;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: drop-shadow(0 0 10px rgba(0, 255, 127, 0.7));
}

/* Hiệu ứng khi hover */
.zalo-button:hover {
  transform: scale(1.2) rotate(10deg);
  filter: drop-shadow(0 0 15px rgba(0, 255, 127, 1));
}

/* Vòng sóng năng lượng tầng 1 */
.zalo-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  background: radial-gradient(circle, rgba(0, 255, 127, 0.5), transparent);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: zaloWave1 2s infinite ease-out;
  z-index: -1;
}

/* Vòng sóng năng lượng tầng 2 */
.zalo-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  background: radial-gradient(circle, rgba(255, 107, 107, 0.4), transparent);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: zaloWave2 2s infinite ease-out 0.3s; /* Trễ 0.3s so với tầng 1 */
  z-index: -1;
}

/* Animation cho nút chính (rung lắc, xoay, nhấp nháy) */
@keyframes zaloExplosion {
  0% {
    transform: scale(1) rotate(0deg);
    filter: drop-shadow(0 0 10px rgba(0, 89, 255, 0.7));
  }
  20% {
    transform: scale(1.15) rotate(15deg);
    filter: drop-shadow(0 0 15px rgb(4, 238, 255));
  }
  40% {
    transform: scale(1.1) rotate(-15deg);
    filter: drop-shadow(0 0 20px rgba(207, 252, 5, 0.9));
  }
  60% {
    transform: scale(1.2) rotate(10deg);
    filter: drop-shadow(0 0 15px rgb(255, 209, 4));
  }
  80% {
    transform: scale(1.1) rotate(-10deg);
    filter: drop-shadow(0 0 20px rgba(2, 87, 216, 0.9));
  }
  100% {
    transform: scale(1) rotate(0deg);
    filter: drop-shadow(0 0 10px rgba(216, 253, 5, 0.7));
  }
}

/* Animation cho sóng năng lượng tầng 1 */
@keyframes zaloWave1 {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
}

/* Animation cho sóng năng lượng tầng 2 */
@keyframes zaloWave2 {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
/* Gradient nền động cho trang đăng nhập/đăng ký */
.login-register-container {
  min-height: 100vh;
  background: linear-gradient(to top, #ff4d4d 10%, #ff8c00 40%, #fff5e6 70%);
  background-size: 100% 300%;
  animation: flameGradient 5s ease-in-out infinite;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

/* Animation gradient */
@keyframes flameGradient {
  0% { background-position: 0% 100%; }
  50% { background-position: 0% 0%; }
  100% { background-position: 0% 100%; }
}

/* Dòng chữ phía trên box */
.header-text {
  text-align: center;
  margin-bottom: 20px;
  color: #2d3436;
}

.header-text .fixed {
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Thêm bóng để dễ đọc */
}

.header-text .typing {
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-block;
  border-right: 2px solid #ff6b6b;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 3s steps(30, end) infinite, blink 0.5s step-end infinite, colorChange 5s ease-in-out infinite;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Thêm bóng */
}

/* Animation gõ chữ */
@keyframes typing {
  0% { width: 0; }
  50% { width: 100%; }
  100% { width: 0; }
}

/* Animation nhấp nháy con trỏ */
@keyframes blink {
  50% { border-color: transparent; }
}

/* Animation thay đổi màu chữ */
@keyframes colorChange {
  0% { color: #ffffff; } /* Trắng khi nền đỏ */
  50% { color: #2d3436; } /* Tối khi nền trắng */
  100% { color: #ffffff; } /* Trở lại trắng */
}

/* Container chính */
.login-register-box {
  position: relative;
  z-index: 1;
  width: 700px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Mũi tên điều hướng */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 107, 107, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
}

.arrow:hover {
  background: #ff6b6b;
  transform: translateY(-50%) scale(1.1);
}

.arrow-left {
  left: -50px;
}

.arrow-right {
  right: -50px;
}

/* Hiệu ứng trượt cho hộp đăng nhập/đăng ký */
.login-box, .register-box {
  transition: transform 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.7s ease;
  width: 100%;
  padding: 30px;
}

/* Login box khi không active */
.login-box.inactive {
  transform: translateX(-100%);
  opacity: 0;
  position: absolute;
  left: -100%;
}

/* Register box khi không active */
.register-box.inactive {
  transform: translateX(100%);
  opacity: 0;
  position: absolute;
  right: -100%;
}

/* Login box khi active */
.login-box.active {
  transform: translateX(0);
  opacity: 1;
  position: relative;
}

/* Register box khi active */
.register-box.active {
  transform: translateX(0);
  opacity: 1;
  position: relative;
}

/* Nút chuyển đổi */
.switch-button {
  height: 100%;
  border-radius: 0 !important;
  border-color: #ff6b6b !important;
  color: #ff6b6b !important;
  background: #fff5e6;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.switch-button:hover {
  background-color: #ff6b6b !important;
  color: #fff !important;
  transform: scale(1.05);
}

/* Biểu tượng bay lơ lửng */
.floating-icon {
  position: absolute;
  z-index: 0;
  width: 60px;
  height: 60px;
  object-fit: contain;
  animation: floatAndRotate 8s ease-in-out infinite;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.2));
}

/* Animation bay và xoay */
@keyframes floatAndRotate {
  0% { transform: translate(0, 0) rotate(0deg) scale(1); }
  25% { transform: translate(5vw, -5vh) rotate(90deg) scale(1.2); }
  50% { transform: translate(-5vw, 5vh) rotate(180deg) scale(1); }
  75% { transform: translate(5vw, -5vh) rotate(270deg) scale(1.3); }
  100% { transform: translate(0, 0) rotate(360deg) scale(1); }
}

/* Typography và khoảng cách */
.login-box h5, .register-box h5 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2d3436;
  margin-bottom: 20px;
}

.login-box .MuiTextField-root, .register-box .MuiTextField-root {
  margin-bottom: 20px;
}

.login-box .MuiButton-root, .register-box .MuiButton-root {
  padding: 10px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  background: #ff6b6b;
  transition: all 0.3s ease;
}

.login-box .MuiButton-root:hover, .register-box .MuiButton-root:hover {
  background: #ff4d4d;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.login-box a, .register-box a {
  color: #ff6b6b;
  text-decoration: none;
  font-size: 0.9rem;
}

.login-box a:hover, .register-box a:hover {
  text-decoration: underline;
}

/* Responsive cho mobile */
@media (max-width: 576px) {
  .login-register-box {
    max-width: 100%;
    margin: 20px 10px;
    padding: 15px;
    flex-direction: column;
  }

  .login-box, .register-box {
    width: 100%;
  }

  .login-box.inactive, .register-box.inactive {
    position: relative;
    transform: translateX(0);
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .login-box.active, .register-box.active {
    height: auto;
  }

  .switch-button {
    height: 50px;
    margin: 10px 0;
  }

  .floating-icon {
    width: 40px;
    height: 40px;
    animation-duration: 6s;
  }

  .arrow {
    display: none;
  }

  .header-text .fixed {
    font-size: 1.2rem;
  }

  .header-text .typing {
    font-size: 1rem;
  }
}
/* Style cho Lịch sử mua hàng */
.order-history-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-history-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.order-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  background: #f8f9fa;
}

.order-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-item-details {
  flex-grow: 1;
}

.status-tag {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
}

.status-pending {
  color: #007bff;
  border: 1px solid #007bff;
}

.status-success {
  color: #28a745;
  border: 1px solid #28a745;
}

.status-failed {
  color: #dc3545;
  border: 1px solid #dc3545;
}

/* Style cho Quản lý đơn hàng */
.order-management-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.order-management-table thead {
  background: linear-gradient(90deg, #007bff, #0056b3);
}

.order-management-table th {
  color: #fff;
  font-weight: 600;
}

.order-management-table tbody tr {
  transition: all 0.3s ease;
}

.order-management-table tbody tr:hover {
  background: #f8f9fa;
  transform: scale(1.01);
}

.order-item-detail {
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-btn {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.85rem;
  transition: all 0.3s ease;
}

.action-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dialog-content {
  padding: 20px;
}

.dialog-textfield {
  margin-bottom: 15px;
}

/* Responsive */
@media (max-width: 576px) {
  .order-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .order-item img {
    width: 60px;
    height: 60px;
  }

  .order-item-details {
    width: 100%;
  }

  .order-management-table th,
  .order-management-table td {
    font-size: 0.85rem;
    padding: 8px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .action-btn {
    width: 100%;
    text-align: center;
  }

  .dialog-content {
    padding: 15px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .order-item {
    gap: 15px;
  }

  .order-item img {
    width: 70px;
    height: 70px;
  }

  .action-buttons {
    gap: 8px;
  }
}